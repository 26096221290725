import styled from "styled-components"
import { useProjects } from "../../../hooks/projects"
import useAppContext from "../../../context/use-app-context"
import Skeleton from "react-loading-skeleton"

const Empresa = window.location.href;
let activeBgColor = "#f25b09";

if(Empresa.includes("argobr")) {
  activeBgColor = "#0F1B8D"
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const MenuSectionTitle = styled.div`
  font-size: 16px;
  padding: 2px 15px;
  font-weight: bolder;
`

const MenuItem = styled.a<{ active?: boolean }>`
  font-size: 16px;
  padding: 5px 15px;
  background-color: ${props => props.active && activeBgColor};
  user-select: none;
  cursor: pointer;
`

const SkeletonWrapper = styled.div`
  font-size: 16px;
  padding: 5px 15px;
`

const ProjectSelector = () => {
  const { projectId, onProjectSelected } = useAppContext()
  const { data: projects } = useProjects()

  return (
    <Wrapper>
      <MenuSectionTitle>
        Projetos
      </MenuSectionTitle>
      {
        projects ? (
          projects.map(project => (
            <MenuItem active={projectId === project.id} onClick={() => onProjectSelected(project.id)}>
              {project.name}
            </MenuItem>
          ))
        ) : (
          <SkeletonWrapper>
            <Skeleton baseColor="" highlightColor="#3642b5"  width={100} />
          </SkeletonWrapper>
        )
      }
    </Wrapper>
  )
}

export default ProjectSelector