import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface CodeProps {
  code: string;
}

const Container = styled.div`
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  border: solid 1px #666666;
`;

const CopyButton = styled.button`
  padding: 8px 15px;
  background-color: transparent;
  border: none;
  color: #F5F5F5;
  cursor: pointer;
  user-select: none;
`

const CodeBlock = styled.pre`
  margin: 0;
  background-color: #3b3b3b;
  color: #F5F5F5;
  overflow-x: auto;
  box-sizing: border-box;
  padding: 10px;
  font-family: 'Courier New', Courier, monospace;
  white-space: pre-wrap;
  width: 100%;
`;

const CodeHeader = styled.div`
  display: flex;
  justify-content: end;
  background-color: #666666;
`

const Code: React.FC<CodeProps> = ({ code }) => {
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(code);
      setCopySuccess('Copiado!');
    } catch (err) {
      setCopySuccess('Falha ao copiar!');
    }
  }

  useEffect(() => {
    if (!copySuccess) {
      return
    }

    const handle = setTimeout(() => {
      setCopySuccess('')
    }, 1500)

    return () => clearTimeout(handle)
  }, [ copySuccess ])

  return (
    <Container>
      <CodeHeader>
        <CopyButton onClick={copyToClipboard}>
          {copySuccess || "Copiar"}
        </CopyButton>
      </CodeHeader>
      <CodeBlock>{code}</CodeBlock>
    </Container>
  );
};

export default Code;
