import withRedirectUnauthenticated from "../../../hocs/with-redirect-unauthenticated"
import Button from "../../ui/button"
import Column from "../../ui/column"
import Row from "../../ui/row"
import Text from "../../ui/text"
import Title from "../../ui/title"
import Layout from "../layout/layout"

const Empresa = window.location.href;
let apiLink = "https://api.terabr.com"

if(Empresa.includes("argobr")) {
  apiLink = "https://api.argobr.com"
}

const DocumentationPage = () => {
  return (
    <Layout>
      <Column padding="15px" gap="15px">
        <Title>
          Documentação
        </Title>
        <Text>
          Acesse a documentação para consultar como utilizar a API.
        </Text>
        <Row>
          <Button onClick={() => window.open(apiLink, '_blank')}>
            Acessar Documentação
          </Button>
        </Row>
      </Column>
    </Layout>
  )
}

export default withRedirectUnauthenticated(DocumentationPage)