import styled from "styled-components"
import { useCurrentUser } from "../../../hooks/user"
import { useEffect, useRef, useState } from "react"
import { useLogout } from "../../../hooks/session"
import Skeleton from "react-loading-skeleton"

const Empresa = window.location.href;
let activeBgColor = "#f25b09";

if(Empresa.includes("argobr")) {
  activeBgColor = "#0F1B8D"
}

const Wrapper = styled.div`
  height: 100%;
  position: relative;
`

const AccountOptionsButton = styled.div<{ active: boolean }>`
  display: flex;
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  user-select: none;
  color: ${props => props.active ? "white" : "#3b3b3b"};
  background-color: ${props => props.active ? `${activeBgColor} !important` : "white"};
  &:hover {
    background-color: lightgray;
  }
`

const Username = styled.div`
  font-weight: bolder;
  font-size: 14px;
`

const ExpandIcon = styled.div<{ active: boolean }>`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid ${props => props.active ? "#F5F5F5" : "#3b3b3b"};
`

const Options = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  border: solid 1px #3b3b3b;
  min-width: 150px;
  border-radius: 5px;
  background-color: #F5F5F5;
  overflow: hidden;
`

const Option = styled.div`
  padding: 10px;
  &:hover {
    background-color: lightgray;
    cursor: pointer;
    user-select: none;
  }
`

const AccountOptions = () => {
  const { data: user } = useCurrentUser()
  const optionsRef = useRef<HTMLDivElement>(null)

  const [ expanded, setExpanded ] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target as any)) {
        setExpanded(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [ setExpanded, optionsRef ])

  return (
    <Wrapper>
      <AccountOptionsButton onClick={() => setExpanded(prev => !prev)} active={expanded}>
        <Username>
          {!user ? <Skeleton width={50} /> : user.name}
        </Username>
        <div>
          <ExpandIcon active={expanded} />
        </div>
      </AccountOptionsButton>
      {
        !expanded ? null : (
          <Options ref={optionsRef}>
            <LogoutOption />
          </Options>
        )
      }
    </Wrapper>
  )
}

const LogoutOption = () => {
  const { mutate, isLoading, isError } = useLogout()

  if (isLoading) {
    return (
      <Option>
        Saindo...
      </Option>
    )
  }

  if (isError) {
    return (
      <Option>
        Erro ao Sair!
      </Option>
    )
  }

  return (
    <Option onClick={() => mutate()}>
      Sair
    </Option>
  )
}

export default AccountOptions