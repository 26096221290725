import React, { ReactNode, useState } from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components"

type Props = {
  value?: string | null;
  onChange?: (value: string | null) => void;
  label?: string;
  placeholder?: string;
  type?: "text" | "password";
  invertColors?: boolean;
  description?: ReactNode;
  skeleton?: boolean;
  validationMessage?: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const InputWrapper = styled.div`
  position: relative;
`

const Label = styled.div<{ invertColors: boolean, invalid: boolean }>`
  color: ${props => {
    if (props.invertColors) {
      return "#F5F5F5"
    }
    if (props.invalid) {
      return "red"
    }
    return "#3b3b3b"
  }};
  font-weight: bolder;
  font-size: 14px;
`

const SkeletonWrapper = styled.div`
  line-height: 1;
  margin-top: 2px;
`

const Input = styled.input<{ invalid: boolean }>`
  height: 40px;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 2px;
  border: solid 1px ${props => {
    if (props.invalid) {
      return "red"
    }
    return "#3b3b3b"
  }};
  outline-color: ${props => {
    if (props.invalid) {
      return "red"
    }
    return "#3b3b3b"
  }};
`

const ValidationMessage = styled.div`
  color: red;
  font-size: 12px;
  position: absolute;
  top: 100%;
  right: 0;
`

const Description = styled.div`
  font-size: 12px;
  height: 12px;
`

const TextField = (props: Props) => {
  const {
    label, value, onChange: baseOnChange, placeholder, type,
    invertColors = false, description, skeleton = false,
    validationMessage
  } = props

  const [ touched, setTouched ] = useState(false)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    baseOnChange?.(e.target.value !== "" ? e.target.value : null)
  }

  const onFocus = (e: React.FocusEvent) => {
    setTouched(true)
  }

  const invalid = touched && validationMessage !== undefined

  return (
    <Wrapper>
      {
        !label ? null : (
          <Label invertColors={invertColors} invalid={invalid}>
            {label}
          </Label>
        )
      }
      {
        skeleton ? (
          <SkeletonWrapper>
            <Skeleton height={40} />
          </SkeletonWrapper>
        ) : (
          <InputWrapper>
            <Input
              value={value === null ? "" : value}
              onChange={onChange}
              placeholder={placeholder}
              type={type}
              onFocus={onFocus}
              invalid={invalid}
            />
            {
              !invalid ? null : (
                <ValidationMessage>
                  {validationMessage}
                </ValidationMessage>
              )
            }
          </InputWrapper>
        )
      }
      {
        !description ? null : (
          <Description>
            {
              invalid ? " " : description
            }
          </Description>
        )
      }
    </Wrapper>
  )
}

export default TextField