import React from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components"

type Props = {
  value?: string | null;
  onChange?: (value: string | null) => void;
  label?: string;
  invertColors?: boolean;
  options?: { value: string | null, description: string }[];
  skeleton?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Label = styled.div<{ invertColors: boolean }>`
  color: ${props => props.invertColors ? "#F5F5F5" : "#3b3b3b"};
  font-weight: bolder;
  font-size: 14px;
`

const Select = styled.select`
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  margin-top: 2px;
  border: solid 1px #3b3b3b;
`

const SkeletonWrapper = styled.div`
  line-height: 1;
  margin-top: 2px;
`

const SelectField = (props: Props) => {
  const {
    label, value, onChange: baseOnChange, invertColors = false, options = [], skeleton = false
  } = props

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    baseOnChange?.(e.target.value !== "" ? e.target.value : null)
  }

  return (
    <Wrapper>
      {
        !label ? null : (
          <Label invertColors={invertColors}>
            {label}
          </Label>
        )
      }
      {
        skeleton ? (
          <SkeletonWrapper>
            <Skeleton height={40} />
          </SkeletonWrapper>
        ) : (
          <Select
            value={value === null ? "" : value}
            onChange={onChange}
          >
            {
              options.map(option => (
                <option key={option.value || ""} value={option.value || ""}>
                  {option.description}
                </option>
              ))
            }
          </Select>
        )
      }
      
    </Wrapper>
  )
}

export default SelectField