import { ReactNode } from "react"
import styled from "styled-components"

const Empresa = window.location.href;
let bgColor = "#ff8340";
let activeBgColor = "#E55200";

if(Empresa.includes("argobr")) {
  bgColor = "#1F2BA0"
  activeBgColor = "#0F1B8D"
}

type Props = {
  page?: number;
  onChange?: (page: number) => void;
  total?: number;
}

const Page = styled.div<{ active: boolean }>`
  background-color: ${props => {
    if (props.active) {
      return activeBgColor
    }
    return bgColor
  }};
  color: #F5F5F5;
  font-size: 14px;
  width: 25px;
  line-height: 25px;
  text-align: center;
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color: ${activeBgColor} !important;
  }
`

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`

const Pagination = (props: Props) => {
  const {
    page, onChange, total = 1
  } = props

  if (total <= 1) {
    return null
  }

  const pages: ReactNode[] = []

  for (let i = 1; i <= total; i++) {
    pages.push(
      <Page active={i === page} onClick={() => onChange?.(i)}>
        {i}
      </Page>
    )
  }

  return (
    <Wrapper>
      {pages}
    </Wrapper>
  )
}

export default Pagination