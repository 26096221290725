import styled from "styled-components"

type Props = {
  status: string
}

const Wrapper = styled.div<{ status?: string }>`
  padding: 1px 10px;
  background-color: ${({ status }) => {
    if (status === "RECEIVED") {
      return "orange"
    }
    if (status === "OK") {
      return "green"
    }
    if (status === "ERROR") {
      return "red"
    }
    return "lightgray"
  }};
  border: solid 1px ${({ status }) => {
    if (status === "RECEIVED") {
      return "darkorange"
    }
    if (status === "OK") {
      return "darkgreen"
    }
    if (status === "ERROR") {
      return "darkred"
    }
    return "gray"
  }};
  color: #F5F5F5;
  font-weight: bolder;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 12px;
`

const ReceiptStatusBullet = (props: Props) => {
  return (
    <Wrapper status={props.status}>
      {adjustText(props.status)}
    </Wrapper>
  )
}

const adjustText = (status: string) => {
  if (status === "RECEIVED") return "Recebida"
  if (status === "ERROR") return "Erro"
  if (status === "NOT_FOUND") return "Não Enviada"
  return status
}

export default ReceiptStatusBullet