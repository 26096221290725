import styled from "styled-components"
import Tabs from "../../ui/tabs"
import Column from "../../ui/column"
import useUrlState from "../../../hooks/use-url-state"
import LoginTab from "./login-tab"
import RecoverPasswordTab from "./recover-password-tab"

const Empresa = window.location.href;
let bgColor = "#ff8340";
let nome = "TERA"

if(Empresa.includes("argobr")) {
  bgColor = "#1F2BA0"
  nome = "ARGO"
}

const Wrapper = styled.div`
  background-color: #F5F5F5;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CenterBox = styled.div`
  width: 400px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  background-color: ${bgColor};
  padding: 4em;
  border-radius: 50px 10px 50px 50px;
`

const Title = styled.div`
  font-size: 26px;
  font-weight: bolder;
  color: #F5F5F5;
  user-select: none;
`

const TabContent = styled.div`
  
`

const LoginPage = () => {
  const [ tab, setTab ] = useUrlState("tab", "login")
  return (
    <Wrapper>
      <CenterBox>
        <Column>
          <Title>
            {nome}
          </Title>
          <div>
            <Tabs
              invertColors
              value={tab}
              onChange={tab => setTab(tab)}
              options={[
                { id: "login", name: "Login" },
                { id: "recover-password", name: "Recuperar Senha" }
              ]}
            />
          </div>
        </Column>
        <TabContent>
          {tab !== "login" ? null : <LoginTab />}
          {tab !== "recover-password" ? null : <RecoverPasswordTab />}
        </TabContent>
      </CenterBox>
    </Wrapper>
  )
}

export default LoginPage