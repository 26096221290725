import styled from "styled-components"
import TextField from "../../ui/text-field"
import Button from "../../ui/button"
import Row from "../../ui/row"
import { FormEvent, useState } from "react"
import useRequest from "../../../hooks/use-request"
import Modal from "../../ui/modal"

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const RecoverPasswordTab = () => {
  const [ userEmail, setUserEmail ] = useState<string>("")
  const request = useRequest()
  const [ loading, setLoading ] = useState(false)
  const [ validEmail, setValidEmail ] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState("")

  const handleChange = (value: string | null) => {
    if(value) {
      setUserEmail(value)

      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if(regex.test(value)) {
        setValidEmail(true)
      } else {
        setValidEmail(false)
      }
    } else {
      setUserEmail("")
    }
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setLoading(true)
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const result = await request<{}>({
        method: "POST",
        url: "/v1/auth/recover-password/send",
        publicRequest: true,
        body: {
          "email": userEmail
        }
      })
      setModalMessage(`Email enviado para ${userEmail}`)
    } catch (e) {
      setModalMessage(`Erro na solicitação para ${userEmail}`)
    } finally {
      setModalOpen(true)
      setLoading(false)
    }
  }

  return (
    <Wrapper onSubmit={onSubmit}>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <p>{modalMessage}</p>
      </Modal>
      <TextField
        invertColors
        label="Nome de Usuário ou E-mail"
        placeholder="Nome de Usuário ou E-mail"
        value={userEmail}
        onChange={handleChange}
      />
      <Row align="center" padding="10px">
        <Button loading={loading} disabled={!validEmail} type="submit" layout="welcome" width={180}>
          Recuperar Senha
        </Button>
      </Row>
    </Wrapper>
  )
}

export default RecoverPasswordTab