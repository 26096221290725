import styled from "styled-components"

const Empresa = window.location.href;
let bgColor = "#ff8340";

if(Empresa.includes("argobr")) {
  bgColor = "#1F2BA0"
}

const Table = styled.table<{ mainColumn: number }>`
  border-collapse: collapse;
  td, th {
    white-space: nowrap;
    font-size: 14px;
    padding: 10px;
    text-align: center;
  }
  th {
    background-color: ${bgColor};
    color: #F5F5F5;
  }
  td:nth-child(${props => props.mainColumn}),
  th:nth-child(${props => props.mainColumn}) {
    text-align: left;
    width: 100%;
  }
  tr:nth-child(2n) td {
    background-color: #F2F2F2;
  }
`

export default Table