import React, { ReactNode } from "react"
import { useLocation, useNavigate } from "react-router"
import styled from "styled-components"

const Empresa = window.location.href;
let activeBgColor = "#f25b09";

if(Empresa.includes("argobr")) {
  activeBgColor = "#0F1B8D"
}

const MenuItemWrapper = styled.a<{ active?: boolean }>`
  font-size: 16px;
  padding: 5px 15px;
  background-color: ${props => props.active && activeBgColor};
  user-select: none;
  cursor: pointer;
  color: #F5F5F5;
  text-decoration: none;
  &:hover {
    background-color: ${activeBgColor} !important;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  &:hover ${MenuItemWrapper} {
    background-color: transparent;
  }
`

const Menu = () => {
  return (
    <Wrapper>
      <MenuItem path="/">
        Home
      </MenuItem>
      <MenuItem path="/receipts">
        Notas
      </MenuItem>
      <MenuItem path="/api-tokens">
        Tokens
      </MenuItem>
      <MenuItem path="/webhook">
        Webhook
      </MenuItem>
      <MenuItem path="/docs">
        Documentação
      </MenuItem>
      <MenuItem path="/contact">
        Contato
      </MenuItem>
    </Wrapper>
  )
}

const MenuItem = (props: { path: string, children: ReactNode }) => {
  const {
    path, children
  } = props

  const location = useLocation()
  const navigate = useNavigate()

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault()
    navigate(path)
  }

  return (
    <MenuItemWrapper active={location.pathname === path} onClick={onClick} href={path}>
      {children}
    </MenuItemWrapper>
  )

}

export default Menu